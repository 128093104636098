.AdminDisabled {
  text-align: center;
}

.AdminDisabled-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 10vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .AdminDisabled-logo {
    animation: AdminDisabled-logo-spin infinite 20s linear;
  }
}

.AdminDisabled-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AdminDisabled-link {
  color: #61dafb;
}

@keyframes AdminDisabled-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
