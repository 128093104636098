.AnnouncementDisplay {
    display: grid;
    place-items: center;
    height: 100vh;
    width: 100vw;
    background: rgb(234, 218, 242);
    background: linear-gradient(
      180deg,
      rgba(234, 218, 242, 1) 0%,
      rgba(145, 185, 2122, 1) 74%,
      rgba(171, 239, 251, 1) 100%
    );
  }

.AnnouncementDisplay main {
  display: flex;
  flex-direction: column;
  gap: max(2rem, 10vh);
  width: min(80vw, 80rem);
  text-align: center;
}

.AnnouncementDisplay main h1 {
  font-size: 3rem;
  font-weight: 700;
}

.AnnouncementDisplay main p {
  font-size: 1.5rem;
  white-space: pre-wrap;
}

.AnnouncementDisplay footer {
    position: absolute;
    bottom: 1rem;
}
