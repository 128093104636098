table,
th,
td {
  border: 1px solid black;
}

.display {
  margin: 10px;
  padding: 10px;
  max-width: 600px;
  background-color: #f2f2f2;

  /* no selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.display.selected {
  background-color: #ddeeff;
}

.display__title,
.display__body {
  display: block;
  width: 100%;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.display__title {
  font-size: 1.5em;
  font-weight: bold;
}

.button {
  -webkit-appearance: none;
  border: none;
  background-color: #333444;
  color: #fff;

  padding: 10px 20px;
  margin: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.button[disabled] {
  background-color: #999;
  cursor: not-allowed;
}

