.homepage {
  background-color: #000;
  height: 100vh;
  display: grid;
  place-items: center;
}

.homepage img {
  object-fit: contain;
  height: 75vmin;
  width: 75vmin;
}
