.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: rgb(234, 218, 242);
  background: linear-gradient(
    180deg,
    rgba(234, 218, 242, 1) 0%,
    rgba(145, 185, 2122, 1) 74%,
    rgba(171, 239, 251, 1) 100%
  );
}

.centered-container__box {
  max-width: 650px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}

.centered-container__logo {
  width: 75%;
  max-width: 300px;
  align-self: center;
}
